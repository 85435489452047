import React from 'react';
import Layout from '../components/Layout';
import YouTubeVideo from "../components/YoutubeVideo";

import content from '../content/epk';
import videoContent from '../content/videos';

const {videos} = videoContent;

const MusicPage = () => (
  <Layout>
    <div className="epk">
        <p className="bio">{content.bio}</p>
        <div className="videos">
            {videos.map(video => (
                <YouTubeVideo url={video.url} key={video.title} />
            ))}
        </div>
    </div>
  </Layout>
);

export default MusicPage;
